<template>
    <div>
        <!-- 顶部 -->
        <el-row>
            <el-col :span='24'>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input placeholder="输入教材名称/编号" v-model='filters.name'></el-input>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-tree
            :props="props"
            :load="loadNode"
            lazy style='width:800px;'>
            <span slot-scope="{node}" class="custom-tree-node">
               <span>{{ node.label }}</span>
               <span>
                    <el-button
                        type="primary"
                        size="mini"
                        @click="clickDetail">
                        视频目录管理
                    </el-button>
               </span>
            </span>
        </el-tree>
    </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs'
export default {
    components:{Toolbar},
    data(){
        return {
            filters:{
                name:''
            },
            buttonList:[],
            // 表格树
            count:1,
            props:{
                label:'name',
                children:'aaa'
            }
        }
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 表格树懒加载
        loadNode(node,resolve){
            if(node.level === 0){
                return resolve([{ name:'单位消防安全责任人、管理人、管理人员' }, { name:'社区（村）消防工作负责人' }])
            }
            if(node.level > 3)return resolve([])
            var hasChild
            if(node.data.name === '单位消防安全责任人、管理人、管理人员'){
                hasChild = true
            }else if(node.data.name === '社区（村）消防工作负责人'){
                hasChild = false
            }else{
                hasChild = Math.random() > 0.5
            }
            setTimeout(() => {
                var data
                if(hasChild){
                    data = [
                        {name:'a' + this.count++},
                        {name:'a' + this.count++}
                    ]
                }else{data = []}
                resolve(data)
            },500)
        },
        // 跳入视频目录管理页面
        clickDetail(){
            this.$router.replace({path:'/VideoCatelogueManage'})
        }
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // custom-tree-node {
    //     flex: 1;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     font-size: 14px;
    //     padding-right: 8px;
    // }
    >>>.el-tree-node__content{
        height:35px;
    }
</style>